body {
  background-color: #f7f6f9;
  color: #24292e;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;
}

h2 {
  margin-top: 0px;
}

iframe {
  border: 0;
  max-width: 800px;
  width: 100%;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1120px;
  padding: 48px 12px;
}

.lead {
  background-color: #daaeec;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 64px;
  }
}

.profile-img {
  border-radius: 120px;
}

.cta {
  background-color: #eb6d20;
  border-radius: 4px;
  color: #fff;
  text-decoration: none;
  display: flex;
  font-size: 24px;
  font-weight: 600;
  justify-content: center;
  line-height: 46px;
  padding: 4px 2px 4px 8px;
}

.samples {
  font-size: 16px;
  margin-top: 48px;
  text-align: center;
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    a {
      justify-self: center;
    }
    img {
      object-fit: cover;
    }
  }
}

.consignment {
  background-color: #C0ECAE;
  font-size: 16px;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .samples .container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .cta {
    font-size: 18px;
  }
}

@media only screen and (max-width: 320px) {
  .cta {
    font-size: 16px;
  }
}
